var map;
var worldMap;

function addActionListeners() {
    var loginWidth = $('#login').outerWidth();
    var contactWidth = $('#contact').outerWidth();
    var header_height = $('#wrapper div.header').height();

    $('#wrapper').scroll(function () {
        if ((header_height - 51) <= $('#wrapper').scrollTop()) {
            if ($('#menu').hasClass('scrolledDown') === false) {
                $('#menu').addClass('scrolledDown');
            }
        }


        if ((header_height - 51) > $('#wrapper').scrollTop()) {
            if ($('#menu').hasClass('scrolledDown') === true) {
                $('#menu').removeClass('scrolledDown');
            }
        }
    });

    $('.navbar a[href$="/auth/login"]').click(function (event) {
        event.preventDefault();

        $('#login').show();

        if ($('#login').outerWidth() != $('body').outerWidth()) {
            $('#overlay').show();
        }

        $('#menu').removeClass('navbar-fixed-top');
        $('#menu').css('display', 'none');

        $('#wrapper, #overlay').animate({
            'marginLeft': "-=" + loginWidth + "px"
        }, 400);
    });

    $('.navbar a[href="/contact"]').click(function (event) {
        event.preventDefault();

        $('#contact').show();

        if ($('#contact').outerWidth() != $('body').outerWidth()) {
            $('#overlay').show();
        }

        $('#menu').removeClass('navbar-fixed-top');
        $('#menu').css('display', 'none');

        $('#wrapper, #overlay').animate({
            'marginLeft': "-=" + contactWidth + "px"
        }, 400);
    });

    $('.close-contact-menu').click(function () {
        $('#overlay').hide();
        $('#wrapper, #overlay').animate({
            'marginLeft': "+=" + contactWidth + "px"
        }, 400, function () {
            $('#menu').addClass('navbar-fixed-top');
            $('#menu').css('display', 'block');
            $('#contact').hide();
        });
    });

    $('.close-login-menu').click(function () {
        $('#overlay').hide();
        $('#wrapper, #overlay').animate({
            'marginLeft': "+=" + loginWidth + "px"
        }, 400, function () {
            $('#menu').addClass('navbar-fixed-top');
            $('#menu').css('display', 'block');
            $('#login').hide();
        });
    });
}

function catchLogin() {
    $('#login').submit(function (event) {
        event.preventDefault();

        var data = $('#login form').serialize();

        url = '/auth/login';

        $.ajax({
            url: url,
            data: data,
            type: 'POST',
            dataType: 'json',
            success: function (data) {
                window.location = data.redirectUrl;
            },
            error: function (data) {
                $('.has-error').removeClass('has-error');
                $('.help-block').remove();

                var data = data.responseJSON;

                $.each(data.errors, function (id, error) {
                    showError(id, error);
                });

                $('html, body').animate({
                    scrollTop: $('.has-error:first').offset().top - 10
                }, 100);
            }
        })
    });
}

function showError(id, error) {
    var identifier = String(id);
    identifier = identifier.replace(/\./g, '_');
    var error_text = String(error);

    $('#' + identifier).closest('.form-group').addClass('has-error');

    var dutch_name = $('#' + identifier).attr('data-dutch-name');

    if (dutch_name != undefined) {
        var identifier_text = String(id);
        identifier_text = identifier_text.replace(/_/g, ' ');
        error_text = error_text.replace(identifier_text, dutch_name);
    }

    $('#' + identifier).closest('.form-group').append('<p class="help-block col-md-12">' + error_text + '</p>');
}

$(document).ready(function () {
    addActionListeners();
    catchLogin();

    var windowWidth = $(window).width();

    if (windowWidth > 1023) {
        if ($('#blocks .container .row .col-md-4').length > 3) {
            $('#blocks .container .row .col-md-4').css({
                position: 'absolute',
                top: '50px',
            });

            var blockHeight = 0;
            var firstRowHeight = 0;
            var secondRowHeight = 0;
            var thirdRowHeight = 0;
            var currentBlock = 1;
            var blockWidth = parseInt($('.block').width());

            $('#blocks .container .row .col-md-4').each(function () {
                if (currentBlock > 3) {
                    if (currentBlock % 3 == 1) {
                        $(this).css({
                            top: (firstRowHeight + 50) + 'px',
                        });
                    } else if (currentBlock % 3 == 2) {
                        $(this).css({
                            top: (secondRowHeight + 50) + 'px',
                        });
                    } else if (currentBlock % 3 == 0) {
                        $(this).css({
                            top: (thirdRowHeight + 50) + 'px',
                        });
                    }
                }

                if (currentBlock % 3 == 1) {
                    $(this).css({
                        left: '0px',
                    });

                    firstRowHeight = firstRowHeight + $(this).outerHeight(true);

                    if (blockHeight < firstRowHeight) {
                        blockHeight = firstRowHeight;
                    }
                } else if (currentBlock % 3 == 2) {
                    $(this).css({
                        left: (blockWidth + 30) + 'px',
                    });

                    secondRowHeight = secondRowHeight + $(this).outerHeight(true);

                    if (blockHeight < secondRowHeight) {
                        blockHeight = secondRowHeight;
                    }
                } else if (currentBlock % 3 == 0) {
                    $(this).css({
                        left: ((2 * blockWidth) + (2 * 30)) + 'px',
                    });

                    thirdRowHeight = thirdRowHeight + $(this).outerHeight(true);

                    if (blockHeight < thirdRowHeight) {
                        blockHeight = thirdRowHeight;
                    }
                }

                currentBlock = currentBlock + 1;

                $('#blocks .container .row').height(blockHeight + 'px');
            });
        }
    }
});

function initMaps() {
    initContactMap();

    if ($('#world-map').length > 0)
    {
        initWorldMap();
    }
}

function initContactMap() {
    var position;
    var geo = new google.maps.Geocoder;

    geo.geocode({'address': address + ' ' + zip_code + ' ' + city + ' ' + country}, function (results, status) {
        if (status == google.maps.GeocoderStatus.OK) {
            position = results[0].geometry.location;

            var map = new google.maps.Map(document.getElementById('map'), {
                zoom: 14,
                center: {lat: position.lat(), lng: position.lng()},
                fullscreenControl: false
            });

            var marker = new google.maps.Marker({
                position: map.getCenter(),
                map: map,
                title: 'Klik voor meer informatie'
            });

            var infoWindow = new google.maps.InfoWindow();

            google.maps.event.addListener(marker, 'click', function () {
                var html = '<p class="address">' + shop_name + '<br /><span class="color2">' + address + '</span><br /><span class="color2">' + zip_code + ' ' + city + '</span></p>';
                infoWindow.setContent(html);
                infoWindow.open(map, marker, html);
            });
        } else {
            alert("Geocode was not successful for the following reason: " + status);
        }
    });
}


function initWorldMap() {
    $('.navbar').addClass('scrolledDown');

    var worldMap = new google.maps.Map(document.getElementById('world-map'), {
        zoom: 3,
        center: {lat: 50, lng: -40},
        fullscreenControl: false
    });

    $.each(companies, function(key, company)
    {
        var position;
        var geo = new google.maps.Geocoder;

        if (company.latitude && company.longitude)
        {
            var marker = new google.maps.Marker({
                position: {lat: parseFloat(company.latitude), lng: parseFloat(company.longitude)},
                map: worldMap,
                title: 'Klik voor meer informatie'
            });

            var infoWindow = new google.maps.InfoWindow();

            google.maps.event.addListener(marker, 'click', function () {
                var html = '<p class="address">' + company.code + ' - ' + company.name + '<br /><span class="color2">' + company.address.address + '</span><br /><span class="color2">' + company.address.zip_code + ' ' + company.address.city + '</span></p>';
                infoWindow.setContent(html);
                infoWindow.open(map, marker, html);
            });
        } else
        {
            geo.geocode({'address': company.address.address + ' ' + company.address.zip_code + ' ' + company.city + ' ' + company.address.country.name_en}, function (results, status) {
                if (status == google.maps.GeocoderStatus.OK) {
                    position = results[0].geometry.location;

                    var marker = new google.maps.Marker({
                        position: {lat: position.lat(), lng: position.lng()},
                        map: worldMap,
                        title: 'Klik voor meer informatie'
                    });

                    var infoWindow = new google.maps.InfoWindow();

                    google.maps.event.addListener(marker, 'click', function () {
                        var html = '<p class="address">' + company.code + ' - ' + company.name + '<br /><span class="color2">' + company.address.address + '</span><br /><span class="color2">' + company.address.zip_code + ' ' + company.address.city + '</span></p>';
                        infoWindow.setContent(html);
                        infoWindow.open(map, marker, html);
                    });

                    $.ajax({
                        url: '/company/' + company.id + '/update',
                        type: 'POST',
                        data: {
                            'latitude': position.lat(),
                            'longitude': position.lng()
                        }
                    });
                } else {
                    alert("Geocode was not successful for the following reason: " + status);
                }
            });
        }
    });
}